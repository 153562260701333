import { Link, Outlet, useLocation } from 'react-router-dom';
import org_log from '../../assets/images/company_log.svg';
import white_log from '../../assets/images/company_log_white.svg';
import { Navbar } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { Header, ILinkProps } from './Header';
import { Footer } from './Footer';

export const LinkFooterItem = ({ title, link, selected }: ILinkProps) => {
  return (
    <div className="flex items-center justify-center">
      <Link
        className={`${
          selected === link ? 'font-bold  ' : 'font-bold'
        } text-lg md:text-base`}
        to={link}
      >
        {title}
      </Link>
    </div>
  );
};
export const ToggleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 18H3V16H21V18ZM21 13H3V11H21V13ZM21 8H3V6H21V8Z"
        fill="white"
      />
    </svg>
  );
};
export const Layout = () => {
  const [visible, setVisible] = useState(false);
  let location = useLocation();
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  // console.log(location.pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener('scroll', toggleVisible);

  return (
    <main>
      <Header />
      <Outlet />
      <Footer />
      {visible && (
        <button
          className="fixed bottom-0 right-0 z-10 flex items-center justify-center scrollUp"
          onClick={() => {
            scrollToTop();
          }}
        >
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
            />
          </svg>
        </button>
      )}
    </main>
  );
};
